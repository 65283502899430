// import { IWebApi } from "../interfaces/IWebApi";
import { Interoperability } from "../Interoperability";
import { UCIClientExtensions } from "./UCIClientExtensions";

export interface IFileContent {
   base64: string;
   name: string;
   path?: string;
}
export interface IExecutionContextConnection {
   executionContext: Xrm.Events.EventContext | Xrm.FormContext,
   fileAttribute: string,
}
export interface IFileAttributeConnection {
   fileAttribute: string,
   entityName: string,
   recordId: string,
}
export interface ITalxisFileConnection {
   recordId: string;
}
export interface IDownloadResponse {
   entityType: string;
   id: string;
   fileAttribute: string;
   fileName: string;
}
interface IRequestData {
   entityName: string,
   fileAttribute: string,
   recordId: string;
   primaryKey?: string;
   fileName?: string;
}
/**
   * FileAttribute is class which provides file handling methods for Dataverse environment.
   * @method uploadFileToAttribute 
   * @method downloadFileFromAttribute

*/

export class FileAttribute {
   private _webApi: Xrm.WebApi;
   constructor(webApi: Xrm.WebApi | ComponentFramework.WebApi) {
      this._webApi = Interoperability.WebApi.TryGetXrmImplementation(webApi);
   }
   /**
      * uploadFileToAttribute is method which allows file upload to PowerApps Dataverse.
      * @param file - File which is being uploaded
      * @param connection - Dataverse connection (file saving location)
   */
   public async uploadFileToAttribute(file: IFileContent, connection?: IExecutionContextConnection | IFileAttributeConnection | ITalxisFileConnection): Promise<Xrm.CreateResponse | void> {
      let attributes: IRequestData = await this._getRequestData(connection);
      if (!connection) {
         let data: { talxis_path?: string, talxis_name?: string } = {
            talxis_path: file.path,
            talxis_name: file.name
         };
         let record = await this._webApi.createRecord('talxis_file', data);
         attributes.recordId = record.id;
      }
      attributes.fileName = file.name;
      if (!attributes.primaryKey)
         attributes.primaryKey = (await (Xrm.Utility.getEntityMetadata(attributes.entityName))).PrimaryIdAttribute;
      var initializeFileBlocksUploadRequest = {
         Target: {
            "@odata.type": `Microsoft.Dynamics.CRM.${attributes.entityName}`,
            [attributes.primaryKey]: attributes.recordId.replace('{', '').replace('}', '')
         },
         FileAttributeName: attributes.fileAttribute,
         FileName: attributes.fileName,

         getMetadata: function () {
            return {
               boundParameter: null,
               parameterTypes: {
                  "Target": {
                     "typeName": "mscrm.crmbaseentity",
                     "structuralProperty": 5
                  },
                  "FileAttributeName": {
                     "typeName": "Edm.String",
                     "structuralProperty": 1
                  },
                  "FileName": {
                     "typeName": "Edm.String",
                     "structuralProperty": 1
                  }
               },
               operationType: 0,
               operationName: "InitializeFileBlocksUpload"
            };
         }
      };

      var initializeFileBlocksUploadResponse = await (await this._webApi.online.execute(initializeFileBlocksUploadRequest)).json();
      const byteCharactersToBeUploaded = window.atob(file.base64.split(",")[1]);
      const totalFileSize = byteCharactersToBeUploaded.length;
      const uploadContentCharCodeArray: number[] = new Array(totalFileSize);
      for (let i = 0; i < totalFileSize; i++) {
         uploadContentCharCodeArray[i] = byteCharactersToBeUploaded.charCodeAt(i);
      }

      const uploadContentByteArray = new Uint8Array(uploadContentCharCodeArray);
      var currentUploadPosition = 0;
      let blockNumber = 0;
      let blockSize = totalFileSize;
      // @ts-ignore - When uploading via these actions in Dataverse, we need to chunk the data to 4MB: https://docs.microsoft.com/en-us/dotnet/api/microsoft.crm.sdk.messages.uploadblockrequest.blockdata?view=dataverse-sdk-latest
      if (!TALXIS?.Portal) {
         blockSize = 4096000;
      }
      const blocks: string[] = [];

      do {
         // https://stackoverflow.com/questions/41923409/constructing-a-base-64-string-upto-a-given-length-and-of-same-size
         const blockId = window.btoa(`BlockId${blockNumber.toLocaleString('en-US', {
            minimumIntegerDigits: 6,
            useGrouping: false
         })}`);
         blocks.push(blockId);

         const blockContentBase64 = window.btoa(this._uint8ToString(uploadContentByteArray.slice(currentUploadPosition, currentUploadPosition + blockSize)));

         var uploadBlockRequest = {
            BlockId: blockId,
            BlockData: blockContentBase64,
            FileContinuationToken: initializeFileBlocksUploadResponse.FileContinuationToken,

            getMetadata: function () {
               return {
                  boundParameter: null,
                  parameterTypes: {
                     "BlockId": {
                        "typeName": "Edm.String",
                        "structuralProperty": 1
                     },
                     "BlockData": {
                        "typeName": "Edm.String",
                        "structuralProperty": 1
                     },
                     "FileContinuationToken": {
                        "typeName": "Edm.String",
                        "structuralProperty": 1
                     }
                  },
                  operationType: 0,
                  operationName: "UploadBlock"
               };
            }
         };
         await this._webApi.online.execute(uploadBlockRequest);

         currentUploadPosition += blockSize; //It is important to use the real returned length because the API may not support chunking (EDS)
         blockNumber++;
      }
      while (currentUploadPosition < totalFileSize);

      var commitFileBlocksUploadRequest = {
         BlockList: blocks,
         FileName: attributes.fileName,
         MimeType: "application/octet-stream",
         FileContinuationToken: initializeFileBlocksUploadResponse.FileContinuationToken,

         getMetadata: function () {
            return {
               boundParameter: null,
               parameterTypes: {
                  "BlockList": {
                     "typeName": "Collection(Edm.String)",
                     "structuralProperty": 4
                  },
                  "FileName": {
                     "typeName": "Edm.String",
                     "structuralProperty": 1
                  },
                  "MimeType": {
                     "typeName": "Edm.String",
                     "structuralProperty": 1
                  },
                  "FileContinuationToken": {
                     "typeName": "Edm.String",
                     "structuralProperty": 1
                  }
               },
               operationType: 0,
               operationName: "CommitFileBlocksUpload"
            };
         }
      };
      await this._webApi.online.execute(commitFileBlocksUploadRequest);
      return {
         id: attributes.recordId,
         entityType: attributes.entityName,
      };
   }
   /**
      * downloadFileFromAttribute is method which allows file download from PowerApps Dataverse.
      * @param connection - Dataverse connection
   */
   public async downloadFileFromAttribute(connection: IFileAttributeConnection | IExecutionContextConnection | ITalxisFileConnection, triggerBrowerDownload?: boolean): Promise<IDownloadResponse> {
      let attributes: IRequestData = await this._getRequestData(connection);
      if (!attributes.primaryKey)
         attributes.primaryKey = (await (Xrm.Utility.getEntityMetadata(attributes.entityName))).PrimaryIdAttribute;
      var initializeFileBlocksDownloadRequest = {
         Target: {
            "@odata.type": `Microsoft.Dynamics.CRM.${attributes.entityName}`,
            [attributes.primaryKey]: attributes.recordId.replace('{', '').replace('}', '')
         },
         FileAttributeName: attributes.fileAttribute,

         getMetadata: function () {
            return {
               boundParameter: null,
               parameterTypes: {
                  "Target": {
                     "typeName": "mscrm.crmbaseentity",
                     "structuralProperty": 5
                  },
                  "FileAttributeName": {
                     "typeName": "Edm.String",
                     "structuralProperty": 1
                  }
               },
               operationType: 0,
               operationName: "InitializeFileBlocksDownload"
            };
         }
      };

      var initializeFileBlocksDownloadResponse = await (await this._webApi.online.execute(initializeFileBlocksDownloadRequest)).json();

      var currentDownloadPosition = 0;
      var totalFileSize = initializeFileBlocksDownloadResponse.FileSizeInBytes;
      var downloadedContentByteArray = new Array(totalFileSize);

      do {
         var downloadBlockRequest = {
            Offset: currentDownloadPosition,
            BlockLength: 4194304, //May not by respected (EDS)
            //May also contain URL or the whole file if this is called in TALXIS Portal
            FileContinuationToken: initializeFileBlocksDownloadResponse.FileContinuationToken,

            getMetadata: function () {
               return {
                  boundParameter: null,
                  parameterTypes: {
                     "Offset": {
                        "typeName": "Edm.Int64",
                        "structuralProperty": 1
                     },
                     "BlockLength": {
                        "typeName": "Edm.Int64",
                        "structuralProperty": 1
                     },
                     "FileContinuationToken": {
                        "typeName": "Edm.String",
                        "structuralProperty": 1
                     }
                  },
                  operationType: 0,
                  operationName: "DownloadBlock"
               };
            }
         };

         var downloadBlockResponse = await (await this._webApi.online.execute(downloadBlockRequest)).json();

         var byteCharacters = window.atob(downloadBlockResponse.Data);

         for (let i = 0; i < byteCharacters.length; i++) {
            downloadedContentByteArray[currentDownloadPosition + i] = byteCharacters.charCodeAt(i);
         }

         currentDownloadPosition += byteCharacters.length; //It is important to use the real returned length because the API may not support chunking (EDS)
      }
      while (currentDownloadPosition < totalFileSize);

      if (triggerBrowerDownload) {
         const byteArray = new Uint8Array(downloadedContentByteArray);
         const blob = new Blob([byteArray]);
         const base64 = await blobToBase64(blob);

         Xrm.Navigation.openFile(
            {
               fileContent: base64,
               fileName: initializeFileBlocksDownloadResponse.FileName,
               fileSize: blob.size,
               mimeType: "application/octet-stream"
            },
            // @ts-ignore - @types/xrm specify incorrect interface for OpenFileOptions - https://docs.microsoft.com/en-us/power-apps/developer/model-driven-apps/clientapi/reference/xrm-navigation/openfile
            {
               openMode: 2
            }
         );
      }
      return {
         id: attributes.recordId,
         entityType: attributes.entityName,
         fileAttribute: attributes.fileAttribute,
         fileName: initializeFileBlocksDownloadResponse.FileName
      };
   }
   private async _getRequestData(connection?: IExecutionContextConnection | IFileAttributeConnection | ITalxisFileConnection): Promise<IRequestData> {
      let entityName: string;
      let fileAttribute: string;
      let recordId: string;
      let formContext: Xrm.FormContext | undefined;
      if (!connection) {
         return {
            entityName: 'talxis_file',
            fileAttribute: 'talxis_file'
         } as IRequestData;
      }
      let connectionType = FileAttribute._tryGetConection(connection);
      if (connectionType === 'IExecutionContextConnection') {
         connection = connection as IExecutionContextConnection;
         formContext = (connection.executionContext) ? UCIClientExtensions.tryGetFormContext(connection.executionContext) : undefined;
         if (formContext) {
            entityName = formContext.data.entity.getEntityName();
            recordId = formContext.data.entity.getId();
            return {
               entityName: entityName,
               fileAttribute: connection.fileAttribute,
               recordId: recordId
            } as IRequestData;
         } else
            throw Error('executionContext or formContext incorrect');
      }
      else if (connectionType === 'IFileAttributeConnection') {
         connection = connection as IFileAttributeConnection;
         entityName = (connection as IFileAttributeConnection).entityName;
         recordId = connection.recordId;
         fileAttribute = (connection as IFileAttributeConnection).fileAttribute;
         return {
            entityName: entityName,
            fileAttribute: (connection as IFileAttributeConnection).fileAttribute,
            recordId: recordId
         } as IRequestData;
      }
      else if (connectionType === 'ITalxisFileConnection') {
         connection = connection as ITalxisFileConnection;
         let record = await this._webApi.retrieveRecord('talxis_file', connection.recordId);
         if (record)
            return {
               entityName: 'talxis_file',
               fileAttribute: 'talxis_file',
               recordId: record['talxis_fileid']
            } as IRequestData;
         else
            throw Error('File with record id: ' + connection.recordId + ' was not found');
      }

      return {
         entityName: 'talxis_file',
         fileAttribute: 'talxis_file'
      } as IRequestData;
   }
   // https://stackoverflow.com/questions/12710001/how-to-convert-uint8-array-to-base64-encoded-string
   private _uint8ToString(u8a: Uint8Array): string {
      const chunkSz = 0x8000;
      const c: string[] = [];
      for (let i = 0; i < u8a.length; i += chunkSz) {
         c.push(String.fromCharCode.apply(null, Array.from(u8a.subarray(i, i + chunkSz))));
      }
      return c.join("");
   }
   private static _typeGuards = class {
      public static DetermineIfConnectionIsExecutionContextConnection(toBeDetermined: object): toBeDetermined is IExecutionContextConnection {
         if ((toBeDetermined as IExecutionContextConnection).executionContext) {
            return true;
         }
         return false;
      }

      public static DetermineIfConnectionIsFileAttributeConnection(toBeDetermined: object): toBeDetermined is IFileAttributeConnection {
         if ((toBeDetermined as IFileAttributeConnection).entityName) {
            return true;
         }
         return false;
      }

      public static DetermineIfConnectionIsTalxisFileConnection(toBeDetermined: object): toBeDetermined is ITalxisFileConnection {
         if ((toBeDetermined as ITalxisFileConnection).recordId) {
            return true;
         }
         return false;
      }
   };
   private static _tryGetConection(connection: IExecutionContextConnection | IFileAttributeConnection | ITalxisFileConnection): string | null {
      if (this._typeGuards.DetermineIfConnectionIsExecutionContextConnection(connection)) {
         return 'IExecutionContextConnection';
      }
      else if (this._typeGuards.DetermineIfConnectionIsFileAttributeConnection(connection)) {
         return 'IFileAttributeConnection';
      }
      else if (this._typeGuards.DetermineIfConnectionIsTalxisFileConnection(connection)) {
         return 'ITalxisFileConnection';
      }
      return null;
   }
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
   const reader = new FileReader();
   reader.readAsDataURL(blob);
   return new Promise(resolve => {
      reader.onloadend = () => {
         resolve((reader.result as string).split(",")[1]);
      };
   });
};